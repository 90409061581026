<template>
<div class="modal-scope">
    <img :src="novel.novel_cover" class="cover mb-4">
    <p> ตอนที่ {{novel.chapter_ep_first}} - {{novel.chapter_ep_last}} </p>
    <hr>
    <b-card-group deck>
        <b-card header="รายชื่อตอน">
            <b-list-group v-if="is_loading">
                <b-spinner label="Spinning" class="m-auto"></b-spinner>
            </b-list-group>
            <b-list-group v-else>
                <b-list-group-item v-for="(c,index) in chapter" :key="index" class="text-left">
                    ตอนที่ {{c.chapter_ep}}: {{c.chapter_name}} <br>
                    <div v-if="c.chapter_price == 0" class="text-left">อ่านฟรี</div>
                    <div v-else-if="c.chapter_price > 0 && (c.chapter_buy_id != undefined || c.chapter_buy_id != null)" class="text-center">
                        <b-icon-check></b-icon-check>
                    </div>
                    <div v-else-if="novel.novel_is_hide == 1" class="text-left">ไม่มีขายแล้ว</div>
                    <div v-else class="text-left"><img src="./../../assets/price.png" width="18px" class="mr-1 mb-1" />{{c.chapter_price}}</div>
                </b-list-group-item>
            </b-list-group>
        </b-card>
    </b-card-group>
    <hr>
    <p>ราคา : {{novel.chapter_price | numFormat}} ทอง</p>
    <div class="myCoin mb-3">
        <table style="width:100%">
            <tr>
                <td>ทองที่มีทั้งหมด</td>
                <td><img src="./../../assets/price.png" width="18px" /> <span>{{novel.member_gold| numFormat}}</span></td>
            </tr>
        </table>
    </div>
    <b-alert :show="error" variant="warning">{{err_text}}</b-alert>
    <div v-if="ishide">
        <p class="warn">ยกเลิกการขายแล้ว</p>
    </div>
    <div v-else-if="goldEnough">
        <p class="addCoin" @click="buyChapter(novel)">ซื้อเลย</p>
    </div>
    <div class="text-center" v-else>
        <p class="warn">ทองของคุณไม่เพียงพอ</p>
        <p class="addCoin" @click="goToPayment">เติมทอง</p>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import {
    mapActions,
    mapGetters
} from 'vuex'
import router from './../../router/index'

export default {
    props: ['novel'],
    data() {
        return {
            goldEnough: false,
            error: false,
            err_text: "",
            chapter: [{
                chapter_name: "",
                chapter_ep: 0,
                chapter_length: 0,
                chapter_buy_count: 0,
                chapter_price: 0
            }],
            ishide: false,
            userInfo: JSON.parse(localStorage.getItem('UserInfo')),
            is_loading: true
        }
    },
    mounted() {
        //console.log('buy : ',this.novel)
        this.chapter_list()
        if (parseInt(this.userInfo.member_gold) >= parseInt(this.novel.chapter_price)) {
            this.goldEnough = true
        }
        if(this.novel.ishide == true){
            this.ishide = true
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'GET_USER_INFO'
        })
    },
    methods: {
        ...mapActions(['SET_MEMBER_GOLD']),
        goToPayment() {
            router.push("/payment");
        },
        async chapter_list(){
            this.is_loading = true
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            formData.append('chapter_id_set', this.novel.chapter_id_set);
            try {
                await axios.post('https://api2.novelrealm.com/module/chapter&submodule=multidetail', formData)
                    .then((res) => {
                        this.chapter = res.data
                        this.is_loading = false
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        async buyChapter(novel) {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            formData.append('chapter_id_set', novel.chapter_id_set);
            //console.log('chapter_id : ',formData.get('chapter_id_set'))
            try {
                await axios.post('https://api2.novelrealm.com/module/chapter&action=buypackchapter', formData)
                    .then((res) => {
                        //console.log(res.data.status)
                        if (res.data.status == "200") {
                            this.SET_MEMBER_GOLD(res.data.member_gold)
                            if(novel.closeModal){
                                //console.log('trigger to parent')
                                this.$emit("closeModal");
                            }else{
                                //console.log(novel)
                                router.push("/novel/" + novel.novel_id + "/chapter/" + novel.chapter_id_first)
                            }
                        }
                        else if(res.data.status == "500") {
                            this.error = true
                            this.err_text = "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลที่เพจเฟสบุ๊ค"
                        }
                        else if(res.data.status == "403") {
                            router.push("/novel/" + novel.novel_id + "/chapter/" + novel.chapter_id_first)
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        }
    }
}
</script>

<style scoped>
.modal-scope {
    text-align: center;
}

.cover {
    border-radius: 10px;
    width: 6rem;
}

.myCoin {
    padding: 5px;
    border: 1px solid #EE806B;
    border-radius: 7px;
    text-align: center;
    width: 100%;
}

.warn {
    font-size: 14px;
    color: #ffffff;
    padding: 0px 5px;
    border-radius: 5px;
    background-color: #042446;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.addCoin {
    color: #ffffff;
    font-weight: bold;
    padding: 7px 20px 7px 20px;
    border-radius: 8px;
    background-color: #EE806B;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.addCoin:hover {
    background-color: rgb(235, 116, 92);
    cursor: pointer;
}
</style>
